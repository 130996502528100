.cart-area{
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 40%;
    background-color: white;
    padding: 1em;
    box-shadow: 0px 1px 8px rgba(128, 128, 128, 0.248);
    overflow-y: auto;
}

.cart-item-outer{
    height: 24em;
}

.cart-item{
    box-shadow: 0px 1px 3px var(--grey);
    padding: 0.6em;
}

.action-btn{
    border: 1px solid var(--grey);
    padding: 0em 0.35em;
    cursor: pointer;
    font-weight: 800;
}

.pay-area{
    position: absolute;
    bottom: 1%;
    right: 1%;
}

@media (max-width: 991px){
    .cart-area{
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 60%;
    }
    .cart-item-outer{
        height: 20em;
        overflow-y: auto;
    }   
}

@media (max-width: 767px){
    .cart-area{
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 90%;
    }
}