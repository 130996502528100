.inventory-head{
    background-color: var(--purple);
    color: white;
}

.inventory-head th{
    padding: 0.6em;
}

.inventory-data td{
    padding: 0.6em;
    font-weight: 500;
    border-bottom: 1px solid var(--grey);
}

.inventory-img{
    height: 12em;
    cursor: pointer;
}