.product-area{
    box-shadow: 0px 1px 8px rgba(128, 128, 128, 0.248);
    padding: 1em;
    border-radius: 10px;
}

.product-img{
    height: 10em;
}

.product-name{
    font-size: 1.1em;
    font-weight: 600;
}

.product-info{
    font-size: 0.8em;
    color: grey;
}

.product-price{
    font-size: 1.3em;
    font-weight: 600;
    color: var(--darkblue);
}

.product-discount{
    font-size: 0.9em;
    font-weight: 600;
}

.add-btn{
    background-color: var(--purple);
    border: 0;
    color: white;
    font-weight: 600;
    padding: 0.2em 0.6em;
    border-radius: 25px;
    transition: all 0.2s linear;
}

.add-btn:hover{
    background-color: var(--darkblue);
}

.cart-btn{
    position: fixed;
    bottom: 4%;
    right: 3%;
    background-color: var(--darkblue);
    border: 0;
    padding: 0.4em 0.6em;
    border-radius: 5px;
    color: white;
    font-size: 1.4em;
}

@media (max-width: 768px){
    .product-name{
        font-size: 1em;
    }
    .product-info{
        font-size: 0.7em;
        color: grey;
    }
    .product-price{
        font-size: 1.2em;
    }
    .product-discount{
        font-size: 0.8em;
    }
}

