@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
}

html, body{
  overflow-x: clip;
}

:root {
  --darkblue: #179696;
  --blue: #00b4d8;
  --lightblue: #90e0ef;
  --purple: #AB47BC;
  --transparent: #4ccaf009;
  --grey: rgb(225, 224, 224);
}

.cursor-pointer{
  cursor: pointer;
}

.response-area{
  position: fixed;
  bottom: 2%;
  left: 2%;
  border-radius: 5px;
  width: 95%;
  padding: 0.5em;
  text-align: center;
  background-color: var(--purple);
  color: white;
  font-family: "Poppins", serif;
  font-weight: 500;
  z-index: 9999;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.090);
}