/* .party-btn{
    border: 0;
    border-radius: 5px;
    background: linear-gradient(to top, var(--lightblue), var(--darkblue));
    padding: 0.3em 0.9em;
    color: white;
    font-family: "Poppins", serif;

}

.party-btn:hover{
    background: linear-gradient(to bottom, var(--blue), var(--darkblue));
} */

.detail-dialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    backdrop-filter: blur(2px);
    padding: 1.5em;
    align-content: center;
    overflow-y: auto;
}

.detail-dialog-inner{
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.090);
    background-color: white;
}