.login-bg {
    /* background-image: url("../../assets//login-bg.webp");
    background-position: center;
    background-size: cover; */
    align-content: center;
    text-align: center;
}

.login-left {
    background-color: rgba(255, 255, 255, 0.808);
    padding: 2em;
    box-shadow: 0px 1px 8px rgba(128, 128, 128, 0.248);
    border-radius: 15px;
    margin: auto;
}

.login-logo {
    height: 8em;
}

.login-input {
    padding: 1em;
    width: 100%;
    border: 0;
    box-shadow: 0px 1px 3px var(--purple);
    border-radius: 25px;
    font-weight: 500;
    font-family: "Poppins", serif;
}

.login-input:focus {
    outline: none;
    border: 1px solid var(--purple);
}

.login-btn {
    background-color: var(--purple);
    border: 0;
    padding: 0.7em 2.1em;
    color: white;
    font-weight: 500;
    border-radius: 25px;
    text-transform: uppercase;
    font-family: "Poppins", serif;
    transition: all 0.2s linear;
}

.login-btn:hover {
    background-color: var(--darkblue);
}

.login-right {
    background: linear-gradient(to left, var(--transparent), var(--lightblue));
    /* border-top-left-radius: 400px; */
    border-bottom-left-radius: 800px;
}

.login-right-inner {
    background-color: var(--darkblue);
    height: 100vh;
    /* border-top-left-radius: 400; */
    border-bottom-left-radius: 800px;
    margin: 0em 0em 2em 2em;
    align-content: center;
}

.login-heading {
    font-size: 2.8em;
    font-weight: 700;
    font-family: "Poppins", serif;
    text-transform: uppercase;
    color: white;
    text-shadow: 0px 1px 2px var(--transparent),
        -0px -1px 2px var(--transparent);
}

@media (max-width: 991px) {
    .login-right {
        background: linear-gradient(to left, var(--blue), var(--darkblue));
        border-bottom-left-radius: 200px;
        border-bottom-right-radius: 200px;
    }

    .login-right-inner {
        height: auto;
        border-bottom-left-radius: 200px;
        border-bottom-right-radius: 200px;
        margin: 0em 0.5em 1em 0.5em;
        align-content: center;
        padding: 1em;
    }

    .login-heading {
        font-size: 2.2em;
        font-weight: 700;
        font-family: "Poppins", serif;
        text-transform: uppercase;
        color: white;
        text-shadow: 0px 1px 2px var(--transparent),
            -0px -1px 2px var(--transparent);
    }

}

@media (max-width: 576px) {
    .login-heading {
        font-size: 1.4em;
        font-weight: 700;
        font-family: "Poppins", serif;
        text-transform: uppercase;
        color: white;
    }
    .login-logo {
        height: 6em;
    }
    
}