.banner-bg{
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    color: white;
}

.admin-card{
    background: linear-gradient(to top, var(--purple), var(--darkblue));
    text-align: center;
    color: white;
    padding: 2em 1em;
    border-radius: 10px;
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.090);
    transition: all 0.2s linear;
}

.admin-card:hover {
    background: linear-gradient(to bottom, var(--purple), var(--lightblue));
}

.admin-icon{
    font-size: 2.5em;
}

.admin-heading{
    font-size: 1.2em;
    font-weight: 700;
    font-family: "Poppins", serif;
}

.admin-text{
    font-size: 1.4em;
    font-weight: 800;
    font-family: "Poppins", serif;
}

.admin-btn{
    border: 0;
    padding: 0.4em 0.8em;
    background-color: white;
    border-radius: 25px;
    font-weight: 600;
    letter-spacing: 0.02em;
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.090);
}