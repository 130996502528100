.sidebar-area {
    position: fixed;
    top: 0;
    left: 0;
    padding: 1.5em;
    background: linear-gradient(to top, var(--purple), var(--darkblue));
    width: 20%;
    height: 100%;
    z-index: 99;
    background-size: 200% 200%;
    animation: flowBackground 3s ease infinite;
    border-right: 1px solid whitesmoke;
    overflow-y: auto;
}


@keyframes flowBackground {
    0% {
        background-position: 100% 40%;
    }

    50% {
        background-position: 20% 100%;
    }

    100% {
        background-position: 100% 40%;
    }
}

.sidebar-logo {
    background-color: white;
    border-radius: 100px;
    height: 7em;
    padding: 0.1em;
    border: 6px solid var(--transparent);
}

.sidebar-item {
    width: 100%;
    background: linear-gradient(to top, var(--lightblue), var(--darkblue));
    padding: 0.8em 1.2em;
    border-radius: 25px;
    border: 2px solid white;
    color: white;
    font-weight: 600;
    font-size: 1.1em;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s linear;
}

.sidebar-text {
    text-decoration: none;
    font-family: "Poppins", serif;
    color: white;
    margin-left: 1em;
}

.sidebar-item:hover {
    background: linear-gradient(to bottom, var(--blue), var(--darkblue));
}

.sidebar-area::-webkit-scrollbar {
    width: 2px;
}

.sidebar-area::-webkit-scrollbar-track {
    background-color: var(--grey);
}

.sidebar-area::-webkit-scrollbar-thumb {
    background-color: whitesmoke;
}

.dashboard-sidebar-right {
    position: fixed;
    overflow-y: auto;
    right: 0;
    top: 0;
    width: 80%;
    height: 100%;
    /* background: linear-gradient(to top, var(--purple), white); */
}

.sidebar-area.collapsed .sidebar-text,
.sidebar-area.collapsed .sidebar-logo {
    display: none;
}

.sidebar-area.collapsed {
    text-align: center;
    width: 7%;
}

.sidebar-area.collapsed~.dashboard-sidebar-right {
    width: 93%;
}

.sidebar-area.sidebar-area.collapsed .sidebar-item {
    padding: 0.6em;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.25em;
}

.sidebar-btn{
    position: fixed;
    bottom: 1%;
    left: 1%;
    background: linear-gradient(to top, var(--purple), var(--darkblue));
    color: white;
    padding: 0.8em;
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.090);
    z-index: 99;
    border-radius: 5px;
    cursor: pointer;
    display: none;
}

@media (max-width: 1380px) {
    .sidebar-area {
        padding: 1em;
        width: 23%;
    }
}

@media (max-width: 1170px) {
    .sidebar-area.collapsed {
        text-align: center;
        width: 8%;
    }

    .sidebar-area.collapsed~.dashboard-sidebar-right {
        width: 92%;
    }
    .sidebar-area {
        padding: 1em;
        width: 25%;
    }
}

@media (max-width: 992px) {
    .sidebar-area.collapsed {
        background: transparent;
        border: 0;
    }
    .sidebar-area.collapsed .sidebar-item {
        display: none;
    }
    .sidebar-area.collapsed .sidebar-btn {
        display: block;
    }
    .sidebar-area.collapsed~.dashboard-sidebar-right {
        width: 100%;
    }
    .sidebar-area{
        width: 32%;
    }
}

@media (max-width: 767px){
    .sidebar-area{
        width: 44%;
    }
}


@media (max-width: 575px){
    .sidebar-area{
        width: 64%;
    }
    .sidebar-item {
        padding: 0.6em 1em;
        font-weight: 600;
        font-size: 1em;
    }
    
}