.profile-area-inner{
    border-radius: 10px;
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.090);
    background-color: white;
}

.profile-heading{
    font-size: 1.4em;
    font-weight: 600;
    font-family: "Poppins", serif;
}

.profile-hr{
    background: linear-gradient(to top, var(--purple), var(--darkblue));
    opacity: 1;
    height: 0.15em;
}

.profile-card{
    /* border-radius: 10px; */
    text-align: center;
    box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.090);
}

.profile-img-area{
    background: linear-gradient(to top, var(--purple), var(--darkblue));
    padding: 0.5em 0em;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
}

.profile-img{
    height: 10em;
    filter: grayscale(100%) brightness(200%);
}

.profile-text{
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.090);
    padding: 0.8em;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.9em;
    background-color: white;
    color: grey;
}

.profile-subheading{
    font-size: 1.15em;
    font-weight: 600;
    font-family: "Poppins", serif;
}

.profile-label{
    color: grey;
    font-size: 0.9em;
    font-weight: 600;
    font-style: italic;
}

.profile-input{
    width: 100%;
    border: 1px solid var(--grey);
    padding: 0.6em;
    border-radius: 25px;
    font-weight: 500;
    color: grey;
}

.profile-input:focus{
    color: black;
    outline: 0;
}

@media (max-width: 575px){
    .profile-area-inner{
        border-radius: 0px;
        box-shadow: none;
        padding: 0;
        background-color: white;
    }    
}