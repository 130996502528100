.invoice-dialog-inner{
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.090);
    background-color: white;
}

.invoice-area{
    border: 4px double var(--grey);
    padding: 1em;
}

@media print {
    body * {
        visibility: hidden;
      }
      .invoice-dialog-inner, .invoice-dialog-inner * {
        visibility: visible;
      }
      .invoice-dialog-inner {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
      }
      .invoice-dialog-inner button {
        display: none;
      }
}
  